export const ASSET_MOVEMENT_TYPE = 'asset movement';
export const LIFECYCLE_TRACKING_TYPE = 'lifecycle tracking';
export const ASSET_MOVEMENT_HEADER_TEXT = 'Development View - Asset Movement';
export const LIFECYCLE_TRACKING_HEADER_TEXT = 'Operations View - Lifecycle Tracking';

export const RED_HEX = '#e74c3c';
export const GREEN_HEX = '#2ecc71';
export const YELLOW_HEX = '#f1c40f';

export const DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_BEGIN = 'DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_BEGIN';
export const DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_SUCCESS = 'DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_SUCCESS';
export const DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_FAILURE = 'DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_FAILURE';
export const DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_DISMISS_ERROR = 'DASHBOARD_GET_ASSET_MOVEMENT_BY_PARTS_AGGREGATION_DISMISS_ERROR';
export const DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_BEGIN = 'DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_BEGIN';
export const DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_SUCCESS = 'DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_SUCCESS';
export const DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_FAILURE = 'DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_FAILURE';
export const DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_DISMISS_ERROR = 'DASHBOARD_GET_ASSET_MOVEMENT_BY_STATUS_CATEGORY_AGGREGATION_DISMISS_ERROR';
export const DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_BEGIN = 'DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_BEGIN';
export const DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_SUCCESS = 'DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_SUCCESS';
export const DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_FAILURE = 'DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_FAILURE';
export const DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_DISMISS_ERROR = 'DASHBOARD_GET_ASSET_COST_OF_USE_AGGREGATION_DISMISS_ERROR';
export const DASHBOARD_GET_ASSET_WARRANTY_REMAINING_BEGIN = 'DASHBOARD_GET_ASSET_WARRANTY_REMAINING_BEGIN';
export const DASHBOARD_GET_ASSET_WARRANTY_REMAINING_SUCCESS = 'DASHBOARD_GET_ASSET_WARRANTY_REMAINING_SUCCESS';
export const DASHBOARD_GET_ASSET_WARRANTY_REMAINING_FAILURE = 'DASHBOARD_GET_ASSET_WARRANTY_REMAINING_FAILURE';
export const DASHBOARD_GET_ASSET_WARRANTY_REMAINING_DISMISS_ERROR = 'DASHBOARD_GET_ASSET_WARRANTY_REMAINING_DISMISS_ERROR';
