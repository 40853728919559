import React, { useCallback, useEffect, useContext } from 'react';
import { CommonDataDashboard } from '../common';
import { useGetParts } from './redux/hooks';
import { TableCell, TableRow } from '@material-ui/core';
import { useToggleAdminCommonDialog } from '../admin/redux/hooks';
import { CreatePartDialog, PartDetailsDialog, UploadPartsDialog } from './index';
import { useGetManufacturers } from '../manufacturers/redux/hooks';
import { isEmpty } from 'underscore';
import { useSelector } from 'react-redux';
import AuthContext from "../authentication/AuthContext";
import {CONFIG} from './../../config';

const COLUMNS = ['Assets', 'Manufacturer', 'Location'];


export default function PartsList(props) {
  const user = useContext(AuthContext);
  const {partsData, getParts} = useGetParts();
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const {getManufacturers, manufacturersData} = useGetManufacturers();
  const newParts = useSelector(state => state.parts.newParts);

  useEffect(() => {
      if (user.roleId == CONFIG.ROLES.manufacturer.id) {
          getManufacturers(1, 1000, 'ASC', user.manufacturerId);
      } else {
          getManufacturers(1, 1000, 'ASC');
      }
  }, [getManufacturers, user]);

  useEffect(() => {
      if (user.roleId == CONFIG.ROLES.manufacturer.id) {
          getParts(1, 1000, 'ASC', user.manufacturerId);
      } else {
          getParts(1, 1000);
      }
  }, [getParts, user]);

  useEffect(() => {
    if (!isEmpty(newParts)) {
        if (user.roleId == CONFIG.ROLES.manufacturer.id) {
            getParts(1, 1000, 'ASC', user.manufacturerId);
        } else {
            getParts(1, 1000);
        }
    }
  }, [newParts, getParts, user]);

  const togglePartsUploadDialog = useCallback(
    () => {
      toggleAdminCommonDialog('UploadPartsDialog', true);
    }, [toggleAdminCommonDialog]);

  const togglePartDetailsDialog = useCallback(
    (params) => {
      toggleAdminCommonDialog('PartDetailsDialog', true, params);
    }, [toggleAdminCommonDialog]);

  const handleRowClick = useCallback(
    (row) => {
      togglePartDetailsDialog({partId: row.partId, partDetails: row});
    }, [togglePartDetailsDialog]);

  const renderTableBody = useCallback(
    (rows, page, rowsPerPage) => {
      return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
        <TableRow hover key={row.name} onClick={handleRowClick.bind(this, row)}>
          <TableCell align="center">{row.description}</TableCell>
          <TableCell align="center">{row.manufacturer.description}</TableCell>
          <TableCell align="center">{row.manufacturer.address}</TableCell>
        </TableRow>
      ))
    }, [handleRowClick]);

  return (
    <div className="parts-parts-list">
      <CommonDataDashboard
        location={props.location}
        data={partsData}
        columns={COLUMNS}
        renderTableBody={renderTableBody}
        toggleUploadDialog={togglePartsUploadDialog}
        uploadButtonText="Upload Assets"
        handleAddItem={() => {
          toggleAdminCommonDialog('CreatePartDialog', true, {});
        }}
        onUpdateTableParamsCallback={(params) => {
          // TODO: add fix when table changed page or rows per page, fetch data backend
          console.log('onUpdateTableParamsCallback: ',params);
          // getParts(params.page, params.limit);
        }}
      />

      <UploadPartsDialog />

      <PartDetailsDialog />

      <CreatePartDialog manufacturersData={manufacturersData} />
    </div>
  );
};

