import React from 'react';
import {Container} from "@material-ui/core";
import {ASSET_MOVEMENT_TYPE} from "./redux/constants";
import {AssetMovement, LifecycleTracking} from "./index";

export default function DashboardContent({contentType}) {
  return (
    <Container className="dashboard-dashboard-content">
        {
            contentType === ASSET_MOVEMENT_TYPE
            ? <AssetMovement />
            : <LifecycleTracking />
        }
    </Container>
  );
};
