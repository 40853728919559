import React, { useContext, useEffect, useState } from 'react';
import BaseTable from './BaseTable';
import AuthContext from '../authentication/AuthContext';
import { AppBar, Button, Fab, Toolbar } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';


const Header = (props) => {
  return (
    <AppBar position="relative" color="default" className="common-base-table-header">
      <Toolbar>
        <div style={{flexGrow: 1}} />

        <div className="header-actions">
          <Button variant="contained" color="secondary" onClick={props.toggleUploadDialog}>
            {props.uploadButtonText}
          </Button>
          {
            props.handleAddItem
            ? <Fab size="medium"
                   color="secondary"
                   aria-label="add"
                   className="datatable-add-item-btn"
                   onClick={props.handleAddItem}
              >
                <AddIcon />
              </Fab>
            : null
          }
        </div>
      </Toolbar>
    </AppBar>
  )
};


export default function CommonDataTable(props) {
  const user = useContext(AuthContext);
  const data = props.data;

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!data || !data.rows) return;

    setRows(
      data.rows
    );
  }, [data]);

  const MyHeader = () => <Header user={user}
                                 toggleUploadDialog={props.toggleUploadDialog}
                                 uploadButtonText={props.uploadButtonText}
                                 handleAddItem={props.handleAddItem} />;


  return (
    <div className="common-common-data-table fob-content-body">
      <BaseTable headerComponent={MyHeader}
                 columns={props.columns}
                 rows={rows}
                 renderTableBody={props.renderTableBody}
                 onUpdateTableParamsCallback={props.onUpdateTableParamsCallback}
      />
    </div>
  );
};
