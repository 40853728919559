import React, {useEffect, useState, useCallback} from 'react';
import {
    Grid,
    Typography,
    CircularProgress,
} from "@material-ui/core";
import {ASSET_MOVEMENT_HEADER_TEXT} from "./redux/constants";
import Chart2DDoughnutPaper from "./Chart2DDoughnutPaper";
import {useGetAssetMovementByPartsAggregation} from "./redux/hooks";
import {useGetAssetMovementByStatusCategoryAggregation} from "./redux/getAssetMovementByStatusCategoryAggregation";
import {debounce} from 'underscore';
import {useGetAssetsAddressByUser} from "../equipments/redux/getAssetsAddressByUser";

function AssetStatus({assetsAddresses}) {
    const {getAssetMovementByStatusCategoryAggregation, getAssetMovementByStatusCategoryAggregationData, getAssetMovementByStatusCategoryAggregationPending, getAssetMovementByStatusCategoryAggregationError} = useGetAssetMovementByStatusCategoryAggregation();
    const [searchValue, setSearchValue] = useState(null);
    const [dateRange, setDateRange] = useState({startDate: null, endDate: null});

    useEffect(() => {
        getAssetMovementByStatusCategoryAggregation('', null, null);
    }, [getAssetMovementByStatusCategoryAggregation]);

    const handleSearchValueChanged = useCallback((value) => {
        setSearchValue(value);
    }, []);

    const handleDateRangeChanged = useCallback(({startDate, endDate}) => {
        setDateRange({startDate, endDate});
    }, []);

    const handleApplyFilter = useCallback(() => {
        const address = searchValue && searchValue.hasOwnProperty('Description') ? searchValue.Description : searchValue;
        getAssetMovementByStatusCategoryAggregation(address, dateRange.startDate, dateRange.endDate);
    }, [getAssetMovementByStatusCategoryAggregation, searchValue, dateRange]);

    return <Chart2DDoughnutPaper title="Asset Status"
                                 loading={getAssetMovementByStatusCategoryAggregationPending}
                                 data={getAssetMovementByStatusCategoryAggregationData}
                                 searchValue={searchValue}
                                 dateRange={dateRange}
                                 assetsAddresses={assetsAddresses}
                                 onSearchValueChanged={handleSearchValueChanged}
                                 onDateRangeChanged={handleDateRangeChanged}
                                 handleApplyFilter={handleApplyFilter} />
}

function AssetCategory({assetsAddresses}) {
    const {getAssetMovementByPartsAggregation, getAssetMovementByPartsAggregationPending, getAssetMovementByPartsAggregationData, getAssetMovementByPartsAggregationError} = useGetAssetMovementByPartsAggregation();
    const [searchValue, setSearchValue] = useState(null);
    const [dateRange, setDateRange] = useState({startDate: null, endDate: null});

    useEffect(() => {
        getAssetMovementByPartsAggregation('', null, null);
    }, [getAssetMovementByPartsAggregation]);

    const handleSearchValueChanged = useCallback((value) => {
        setSearchValue(value);
    }, []);

    const handleDateRangeChanged = useCallback(({startDate, endDate}) => {
        setDateRange({startDate, endDate});
    }, []);

    const handleApplyFilter = useCallback(() => {
        const address = searchValue && searchValue.hasOwnProperty('Description') ? searchValue.Description : searchValue;
        getAssetMovementByPartsAggregation(address, dateRange.startDate, dateRange.endDate);
    }, [getAssetMovementByPartsAggregation, searchValue, dateRange]);

    return <Chart2DDoughnutPaper title="Asset Category"
                                 loading={getAssetMovementByPartsAggregationPending}
                                 data={getAssetMovementByPartsAggregationData}
                                 searchValue={searchValue}
                                 dateRange={dateRange}
                                 assetsAddresses={assetsAddresses}
                                 onSearchValueChanged={handleSearchValueChanged}
                                 onDateRangeChanged={handleDateRangeChanged}
                                 handleApplyFilter={handleApplyFilter} />
}

export default function AssetMovement() {
  const {getAssetsAddressByUser, assetsAddresses} = useGetAssetsAddressByUser();

  useEffect(() => {
    getAssetsAddressByUser();
  }, [getAssetsAddressByUser]);

  return (
    <Grid className="dashboard-asset-movement"
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center">
        <Grid item>
            <Typography color="primary" className="dashboard-dashboard-content-header-title" variant="h6">{ASSET_MOVEMENT_HEADER_TEXT}</Typography>
        </Grid>

        <Grid item
              container
              direction="row"
              alignItems="center"
              spacing={2}
              style={{justifyContent: 'space-around'}}>
            <Grid item
                  container
                  alignItems="center"
                  style={{justifyContent: 'center'}}
                  md={12}
                  lg={6}>
                <AssetStatus assetsAddresses={assetsAddresses} />
            </Grid>

            <Grid item
                  container
                  alignItems="center"
                  style={{justifyContent: 'center'}}
                  md={12}
                  lg={6}>
                <AssetCategory assetsAddresses={assetsAddresses} />
            </Grid>
        </Grid>
    </Grid>
  );
};
