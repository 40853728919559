import React, { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid,
  IconButton, TextField,
  Toolbar,
  Typography,
  Container
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import { useToggleAdminCommonDialog } from '../admin/redux/toggleAdminCommonDialog';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { isEmpty } from 'underscore';
import { useGetPartDetails } from './redux/hooks';
import {useUpdatePart} from "./redux/updatePart";
import LoadingButton from "../common/LoadingButton";
import Swal from "sweetalert2";
import {useGetParts} from "./redux/getParts";


const validationSchema = Yup.object({
  part: Yup.string().required('Required'),
});


export default function PartDetailsDialog() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleDialog = useSelector(state => state.admin.toggleDialog);
  const partDetails = useSelector(state => state.parts.partDetails);
  const {toggleAdminCommonDialog} = useToggleAdminCommonDialog();
  const {getPartDetails} = useGetPartDetails();
  const {updatePart, updatePartPending} = useUpdatePart();
  const {getParts} = useGetParts();

  useEffect(() => {
    if (isEmpty(toggleDialog) || !toggleDialog.partId) return;

    getPartDetails(toggleDialog)
  }, [toggleDialog, getPartDetails]);

  const formik = useFormik({
    initialValues: {
      manufacturer: partDetails && partDetails.manufacturer.description,
      part: partDetails && partDetails.description,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      updatePart(partDetails.partId, values.part)
          .then(res => {
              Swal.fire({
                  icon: 'success',
                  text: res.message,
                  timer: 2000
              }).then(() => {
                  getParts(1, 1000);
                  toggleAdminCommonDialog('PartDetailsDialog', false, {});
              });
          });
    },
  });

  return (
    <Dialog
      fullScreen={fullScreen}
      open={toggleDialog.name === 'PartDetailsDialog' && toggleDialog.flag === true}
      className="parts-part-details-dialog"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle>
          <Toolbar>
            <Typography variant="h5" display="inline">Asset</Typography>
            <span style={{flexGrow: 1}}></span>
            <IconButton
              edge="end"
              color="inherit" aria-label="menu"
              onClick={toggleAdminCommonDialog.bind(this, 'PartDetailsDialog', false, {})}>
              <HighlightOffIcon />
            </IconButton>
          </Toolbar>
          <Divider />
        </DialogTitle>

        <DialogContent>
          <Container maxWidth="sm">
            <Grid container
                  lg={12}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}>
              <Grid item lg={12}>
                <TextField
                  id="manufacturer-field"
                  label="Manufacturer"
                  InputProps={{disableUnderline: true}}
                  InputLabelProps={{ shrink: true }}
                  className="fob-filled-input-with-outside-label"
                  value={formik.values.manufacturer}
                  disabled
                  fullWidth
                />
              </Grid>

              <Grid item lg={12}>
                <TextField
                  id="part"
                  label="Asset"
                  InputProps={{disableUnderline: true}}
                  InputLabelProps={{ shrink: true }}
                  className="fob-filled-input-with-outside-label"
                  value={formik.values.part}
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Container>
        </DialogContent>

        <DialogActions>
            {
                updatePartPending === true
                    ? <LoadingButton/>
                    : <Button variant="contained"
                              disableElevation
                              color="secondary"
                              type="submit"
                              className="rounded-btn"
                    >
                      Save
                    </Button>
            }

          <Button variant="contained"
                  disableElevation
                  onClick={toggleAdminCommonDialog.bind(this, 'PartDetailsDialog', false, {})}
                  className="rounded-btn dark-btn"
                  disabled={updatePartPending === true}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

