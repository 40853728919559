import axios from 'axios';
import {CONFIG} from './../../../config';
import { findWhere, isEmpty, isNull } from 'underscore';
import AuthService from '../../authentication/services/AuthService';

const API_BASE_URL = CONFIG.API_ADDRESS;
const API_USER_URL = `${API_BASE_URL}/user`;
// const API_MANUFACTURER_URL = 'manufacturers';
// const API_PARTS_URL = 'parts';
const API_ROLES_URL = 'roles';

const globalHeaders = {
  headers: {
    'Content-Type': 'application/json'
  }
};
let token;

const axiosInstance = axios.create({
  baseURL: API_BASE_URL
});
// axiosInstance.defaults.headers.common['accessToken'] = AuthService._getToken();
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
  console.log('request interceptor: ',config);

  token = AuthService._getToken();
  if(!config.url.includes("/signin") && !config.url.includes("/register")) {
    if (token) { 
      config.headers.accessToken = token;
    } else {
      throw new axios.Cancel('Request not allowed');
    }
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => response,
  error => {
    AuthService.checkInvalidTokenError(error);
    return Promise.reject(error);
  }
);

class ApiService {

 /*
  * Authentication Routes
  */

  static signIn(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_USER_URL}/login`, data, globalHeaders)
        .then(
          res => {
              console.log(JSON.stringify(res.data));
            resolve(res.data);
            // token, expires (), statusCode
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static getHeaders(accessToken) {
    let headers = globalHeaders;
    headers['headers']['accessToken'] = accessToken;
    return headers;
  }

  static signOut() {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`user/logout`)
        .then(
          res => {
            resolve(res.data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static register(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_USER_URL}/create`, data)
        .then(
          res => {
            resolve(res.data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static setPass(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_USER_URL}/setpassword`, data)
        .then(
          res => {
            resolve(res.data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static getUserFromTempToken(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_USER_URL}/getUserTempToken`, data)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
                resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static forgotPassword(emailAddress) {
    return new Promise((resolve, reject) => {
      axios.post(`${API_USER_URL}/forgot`, {email: emailAddress})
        .then(
          res => {
            resolve(res.data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static getUserProfile(accessToken) {
    let headers = ApiService.getHeaders(accessToken);

    return new Promise((resolve, reject) => {
      axios.get(`${API_USER_URL}/profile`, headers)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              // if (res.data.body.manufacturerId) {
              //   ApiService.getManufacturerDetails(res.data.body.manufacturerId, accessToken)
              //     .then(manufacRes => {
              //       res.data.body['manufacturer'] = manufacRes;
              //       resolve(res.data.body);
              //     });
              // } else {
                resolve(res.data.body);
              // }
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static updateUserProfile(params) {

    let formData = new FormData();
    formData.append('firstName', params.firstName);
    formData.append('lastName', params.lastName);
    formData.append('phoneNumber', params.phoneNumber);
    formData.append('title', params.title);
    // Since avatar cannot be deleted/only can be updated.
    if(params.file) {
      formData.append('file', params.file);
    }

    return new Promise((resolve, reject) => {
      axiosInstance({
        method: 'put',
        url: 'user/profile/update',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static updateProfilePhoto(data) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: 'put',
        url: 'user/profile/update',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static updateBackgroundImage(data) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: 'put',
        url: 'user/profile/updatebg',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'}
      })
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

    static getOwners(page = 1, limit = 5, order = 'ASC') {
        return new Promise((resolve, reject) => {
            axiosInstance.get(`owners/?limit=${limit}&page=${page}&order=${order}`)
                .then(
                    res => {
                        if (res.data && res.data.body) {
                            resolve(res.data.body);
                        } else {
                            reject(res);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

    static createOwner(data) {
        return new Promise((resolve, reject) => {
            axiosInstance.post('owners/create', data)
                .then(
                    res => {
                        if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
                            resolve(res.data.body);
                        } else {
                            reject(res);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
        });
  }

  static updateOwner(ownerId, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.put(`owners/update/${ownerId}`, params)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

 /*
  * Equipment Routes
  */

  static saveEquipment(data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('assets/create', data)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static updateEquipment(data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('assets/update', data)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static getAssets(ownerId, page=1, limit=5, order='desc', orderColumn='Manufacturer') {
    return new Promise((resolve, reject) => {
      let url = `assets/?limit=${limit}&page=${page}&order=${order}&ownerId=${ownerId}&column=${orderColumn}`;

      axiosInstance.get(url)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static searchAssets(filters, userId, ownerId=null, page = 1, limit = 5, order = 'desc', orderColumn = 'Manufacturer', urlParam=null) {
      return new Promise((resolve, reject) => {
          let url = `assets/search/?limit=${limit}&page=${page}&order=${order}&userId=${userId}&column=${orderColumn}`;
          if (urlParam) url += `&url=${urlParam}`;
          if (ownerId) url += `&ownerId=${ownerId}`;
          for (let filter in filters) {
              if (filters[filter]) url += `&${filter}=${filters[filter]}`;
          }
          axiosInstance.get(url)
              .then(
                  res => {
                      if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
                          resolve(res.data.body);
                      } else {
                          reject(res);
                      }
                  },
                  error => {
                      reject(error);
                  }
              );
      });
  }

  static searchAssetFromQRCode(filters, userId, ownerId=null, orderColumn = 'Manufacturer', urlParam=null) {
      return new Promise((resolve, reject) => {
          let url = `search/?userId=${userId}&column=${orderColumn}&url=${urlParam}`;
          if (ownerId) url += `&ownerId=${ownerId}`;
          for (let filter in filters) {
              if (filters[filter]) url += `&${filter}=${filters[filter]}`;
          }
          axiosInstance.get(url)
              .then(
                  res => {
                      if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
                          resolve(res.data.body);
                      } else {
                          reject(res);
                      }
                  },
                  error => {
                      reject(error);
                  }
              );
      });
  }

  static searchAssetFromLat(latId) {
    return new Promise((resolve, reject) => {
        let url = `search/lat/${latId}`;
        axiosInstance.get(url)
            .then(
                res => {
                    if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
                        resolve(res.data.body);
                    } else {
                        reject(res);
                    }
                },
                error => {
                    reject(error);
                }
            );
    });
}

  static getAssetDetails(assetId, page=1, limit=5, order='ASC') {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`assets/${assetId}`)
        .then(
          res => {
            if (res.data && res.data.body) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static getAssetManufacturerInfo(assetId) {
      return new Promise((resolve, reject) => {
          axiosInstance.get(`assets/manufacturerInfo/${assetId}`)
              .then(
                  res => {
                      if (res.data && res.data.body) {
                          resolve(res.data.body);
                      } else {
                          reject(res);
                      }
                  },
                  error => {
                      reject(error);
                  }
              );
      });
  }

  static getActivities(assetId, page=1, limit=5, order='desc', orderColumn='Activity') {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`activities/?limit=${limit}&page=${page}&order=${order}&assetId=${assetId}&column=activityId`)
        .then(
          res => {
            if (res.data && res.data.body) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static saveActivity(data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('activities/create', data)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static saveActivityWithImage(data) {
    let formData = new FormData();
    formData.append('activity', data.activity);
    formData.append('assetId', data.assetId);
    formData.append('activityTypeId', data.activityTypeId);
    formData.append('authorizedBy', data.authorizedBy);
    formData.append('cost', data.cost);
    formData.append('date', data.date);
    formData.append('notes', data.notes);
    formData.append('ownerId', data.ownerId);
    formData.append('repairTime', data.repairTime);
    formData.append('address', JSON.stringify(data.address));
    // Since image cannot be deleted/only can be updated.
    if(data.files && data.files.length) {
      data.files.forEach(f => {
          formData.append('file', f.source);
      });
    }

    return new Promise((resolve, reject) => {
      axiosInstance({
        method: 'put',
        url: 'activities/createwithimage',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then(
        res => {
          if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
            resolve(res.data.body);
          } else {
            reject(res);
          }
        },
        error => {
          reject(error);
        }
      );
    });
  }

  static getActivityTypes() {
    return new Promise((resolve, reject) => {
      axiosInstance.get('activitytypes')
        .then(
          res => {
            if (res.data && res.data.body) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static getShippers() {
    return new Promise((resolve, reject) => {
      axiosInstance.get('shippers')
        .then(
          res => {
            if (res.data && res.data.body) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  /*
 *  Equipment Warranty Routes
 */

  static createWarranty(data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('warranty/create', data)
        .then(
          res => {
            if (res.data && res.data.body) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static updateWarranty(warrantyId, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.put(`warranty/update/${warrantyId}`, params)
        .then(
          res => {
            if (res.data && res.data.body) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  /**
   * Service Agreement Routes
   *
   */

  static createServiceAgreement(data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('serviceagreements/create', data)
        .then(
          res => {
            if (res.data && res.data.body) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static updateServiceAgreement(serviceAgreementId, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.put(`serviceagreements/update/${serviceAgreementId}`, data)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }


  /*
  * Manufacturers Routes
  */

  static getManufacturers(page=1, limit=3, order='ASC', manufacturerId=null) {
    return new Promise((resolve, reject) => {
      let url = `manufacturers/?limit=${limit}&page=${page}&order=${order}`;
      axiosInstance.get(url)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
                if (manufacturerId) {
                    let data = {...res.data.body};
                    data.rows = data.rows.filter(item => item.manufacturerId === manufacturerId);
                    resolve(data);
                } else {
                    resolve(res.data.body);
                }
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static getManufacturerDetails(manufacturerId, accessToken = null) {
    let headers = {};

    if (accessToken) {
      headers = ApiService.getHeaders(accessToken);
    }

    return new Promise((resolve, reject) => {
      let url = `manufacturers/${manufacturerId}`;

      axiosInstance.get(url, headers)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }


        );
    });
  }

  static importManufacturers(data, onUploadProgress) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: 'post',
        url: 'manufacturers/import',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: onUploadProgress,
      })
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static createManufacturer(data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('manufacturers/create', data)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

    static updateManufacturer(manufacturerId, description, address) {
        return new Promise((resolve, reject) => {
            axiosInstance.put(`manufacturers/update/${manufacturerId}`, {description: description, address: address})
                .then(
                    res => {
                        if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
                            resolve(res.data.body);
                        } else {
                            reject(res);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }
  /*
  * Address Routes
  */
  static getAddresses(page=1, limit=3, order='ASC') {
    return new Promise((resolve, reject) => {
      let url = `address/?limit=page=${page}&order=${order}`;

      axiosInstance.get(url)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  /*
  * Parts Routes
  */

  static getPartsWithAddress(manufacturerId, page=1, limit=3, order='ASC') {
    return new Promise((resolve, reject) => {
      axios.all([ApiService.getParts(manufacturerId, page, limit, order), ApiService.getAddresses()])
        .then(axios.spread(function(partsResponse, addressResponse) {
          let foundAddress;

          if (!isEmpty(partsResponse) && Array.isArray(partsResponse.rows)) {
            partsResponse.rows.forEach((item, index, arr) => {
              foundAddress = findWhere(addressResponse, {addressId: item.manufacturer.addressId});
              arr[index].manufacturer.address = foundAddress ? `${foundAddress.city}, ${foundAddress.state}` : '';
            });
          }

          resolve(partsResponse);
        }));
    });
  }

  static getParts(manufacturerId=null, page=1, limit=3, order='ASC') {
    return new Promise((resolve, reject) => {
      let url = `parts/?limit=${limit}&page=${page}&order=${order}`;
      if (!isNull(manufacturerId)) url += `&manufacturerId=${manufacturerId}`;

      axiosInstance.get(url)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static getAllParts(manufacturerId) {
    return new Promise((resolve, reject) => {
      let url = `parts/?manufacturerId=${manufacturerId}&all=true`;

      axiosInstance.get(url)
        .then(
          res => {
            if (res.data && res.data.body) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static getPartDetails(partId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`parts/${partId}`)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

    static updatePart(partId, description) {
        return new Promise((resolve, reject) => {
            axiosInstance.put(`parts/update/${partId}`, {description: description})
                .then(
                    res => {
                        if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
                            resolve(res.data.body);
                        } else {
                            reject(res);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

  static createPart(data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('parts/create', data)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  static importParts(data, onUploadProgress) {
    return new Promise((resolve, reject) => {
      axiosInstance({
        method: 'post',
        url: 'parts/import',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: onUploadProgress,
      })
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  /*
  * Roles routes
  */

  static getRoles(limit= 100, page=1, order='ASC') {
    return new Promise((resolve, reject) => {
      let url = `${API_ROLES_URL}/?limit=${limit}&page=${page}&order=${order}`;

      axiosInstance.get(url)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

    static updateRole(roleId, description) {
        return new Promise((resolve, reject) => {
            axiosInstance.put(`${API_ROLES_URL}/update/${roleId}`, {description: description})
                .then(
                    res => {
                        if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
                            resolve(res.data.body);
                        } else {
                            reject(res);
                        }
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }

  static createRole(data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`${API_ROLES_URL}/create`, data)
        .then(
          res => {
            if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
              resolve(res.data.body);
            } else {
              reject(res);
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  /*
  * CATEGORIES
  *
  */

  static getCategories() {
      return new Promise((resolve, reject) => {
          axiosInstance.get('categories')
              .then(
                  res => {
                      if (!isEmpty(res.data) && !isEmpty(res.data.body)) {
                          resolve(res.data.body);
                      } else {
                          reject(res);
                      }
                  },
                  error => {
                      reject(error);
                  }
              );
      });
  }

  /*
   * Dashboard - Asset Movement
   *
   */

  static getAssetMovementDataAggregation(type, searchValue, startDate, endDate) {
      return new Promise((resolve, reject) => {
          axiosInstance.get(`assets/movement/aggregation?aggrType=${type}&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}`)
              .then(
                  res => {
                      if (res.data && res.data.body) {
                          resolve(res.data.body);
                      } else {
                          reject(res);
                      }
                  },
                  error => {
                      reject(error);
                  }
              );
      });
  }

  static getAssetMovementByStatusCategoryAggregation(searchValue, startDate, endDate) {
      return ApiService.getAssetMovementDataAggregation('status', searchValue, startDate, endDate);
  }

  static getAssetMovementByPartsAggregation(searchValue, startDate, endDate) {
      return ApiService.getAssetMovementDataAggregation('category', searchValue, startDate, endDate);
  }

  static getAssetLifecycleDataAggregation(type, searchValue, startDate, endDate) {
      return new Promise((resolve, reject) => {
          axiosInstance.get(`assets/lifecycle/aggregation?aggrType=${type}&searchValue=${searchValue}&startDate=${startDate}&endDate=${endDate}`)
              .then(
                  res => {
                      if (res.data && res.data.body) {
                          resolve(res.data.body);
                      } else {
                          reject(res);
                      }
                  },
                  error => {
                      reject(error);
                  }
              );
      });
  }

  static getAssetCostOfUseAggregation(searchValue, startDate, endDate) {
      return ApiService.getAssetLifecycleDataAggregation('costofuse', searchValue, startDate, endDate);
  }

  static getAssetWarrantyRemaining() {
      return ApiService.getAssetLifecycleDataAggregation('warrantyremaining', '', '', '');
  }

  static getAssetsAddressByUser() {
      return new Promise((resolve, reject) => {
          axiosInstance.get('addresses/user')
              .then(
                  res => {
                      if (res.data && res.data.body) {
                          resolve(res.data.body);
                      } else {
                          reject(res);
                      }
                  },
                  error => {
                      reject(error);
                  }
              );
      });
  }
}

export {axiosInstance};
export default ApiService;