import React from 'react';
import { PageHeader } from './index';
import CommonDataTable from './CommonDataTable';


export default function CommonDataDashboard(props) {
  return (
    <div className="common-common-data-dashboard">
      <PageHeader location={props.location} />

      <CommonDataTable columns={props.columns}
                       data={props.data}
                       renderTableBody={props.renderTableBody}
                       toggleUploadDialog={props.toggleUploadDialog}
                       uploadButtonText={props.uploadButtonText}
                       handleAddItem={props.handleAddItem}
                       onUpdateTableParamsCallback={props.onUpdateTableParamsCallback}
      />
    </div>
  );
};

