import React, { useEffect, useRef } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TablePagination,
  LinearProgress
} from '@material-ui/core';
import {isNull} from 'underscore';
import {axiosInstance} from "./services/ApiService";

let rowsInPage;

export default function BaseTable(props) {
  const initialPage = props.initialPage ? (props.initialPage - 1) : 0;
  const initialRowsPerPage = props.initialRowsPerPage ? props.initialRowsPerPage : 5;

  const requestInterceptor = useRef(null);
  const responseInterceptor = useRef(null);

  const onUpdateTableParamsCallback = props.onUpdateTableParamsCallback;

  const [page, setPage] = React.useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    requestInterceptor.current = axiosInstance.interceptors.request.use(config => {
      setLoading(true);
      return config;
    }, function (error) {
      setLoading(false);
      return Promise.reject(error);
    });

    responseInterceptor.current = axiosInstance.interceptors.response.use(response => {
      setLoading(false);
      return response;
    }, function (error) {
      setLoading(false);
      return Promise.reject(error);
    });

    return () => {
      if (requestInterceptor.current) axiosInstance.interceptors.request.eject(requestInterceptor.current);
      if (responseInterceptor.current) axiosInstance.interceptors.request.eject(responseInterceptor.current);
    };
  }, []);


  // reset page to 0 if page > 0 and row count is less than the rowsperpage
  useEffect(() => {
    if (!isNull(props.count)
        && (props.count <= rowsPerPage || props.count <= (rowsPerPage*page))
        && page > 0) {
      setPage(0);
    }
  }, [page, rowsPerPage, props.count]);

  useEffect(() => {
    if (onUpdateTableParamsCallback) {
      onUpdateTableParamsCallback({
        page: page+1,
        limit: rowsPerPage,
        order: 'DESC',
      })
    }
  }, [onUpdateTableParamsCallback, page, rowsPerPage]);


  const handleChangePage = (event, newPage) => {
    if (props.changePage) props.changePage(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    rowsInPage = parseInt(event.target.value, 10);

    if (props.rowsPerPageChanged) props.rowsPerPageChanged(rowsInPage);

    setRowsPerPage(rowsInPage);
    setPage(0);
  };

  return (
    <div className="common-base-table fob-table-container">
      <Card>
        {
          props.headerComponent
          ? <CardHeader component={props.headerComponent} />
          : null
        }

        {
          loading && <LinearProgress color="secondary" />
        }

        <CardContent className="common-base-table-content">
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                  {
                    props.showResultInfo
                    ?   <TableRow key="result" className="result-row-info">
                          <TableCell align="left" colSpan={props.columns.length}><h3>Results Found: {props.count ? props.count : props.rows.length}</h3></TableCell>
                        </TableRow>
                    : null
                  }
                <TableRow>
                  {
                    props.renderTableColumns
                    ? props.renderTableColumns()
                    : props.columns.map(col => {
                        return <TableCell align="center">{col}</TableCell>
                      })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  props.renderTableBody(props.rows, page, rowsPerPage, props.remote)
                }
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>

        <CardActions>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={props.count ? props.count : props.rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Items per page"
          />
        </CardActions>
      </Card>
    </div>
  );
};

